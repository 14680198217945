<template>
	<PopupTwoCol name="sonometre"
	text="<h3>Sonomètres</h3><br/><p>Deux sonomètres ont été placés dans le centre-ville de Donges et sur le bâtiment Astrolabe situé à l’entrée Nord de la raffinerie. Ils sont actifs 24h/24h. En surveillant en continu le niveau sonore, ils nous alertent en cas d’émergences anormales et permettent une plus grande réactivité dans la réduction des nuisances sonores.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/sonometre_tpbbh6_c_scale_w_206.jpg 206w"
		data-src="img/popup/sonometre_tpbbh6_c_scale_w_206.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
